/*その他報告　スライド画像*/
.image-gallery-slide img {
    height: 400px; /*全画像の高さを合わせる*/
    width: calc(100% - 20px);
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    background-color: black;
}

/*その他報告　スライド画像　フルスクリーンモード*/
.fullscreen .image-gallery-slide img {
    height: 80vh;
}

/** TODO kura:スクロールバーと画像がかぶる*/
/*その他報告 サムネイル*/
.image-gallery-thumbnails {
    overflow-y: scroll;
}

/*その他報告 サムネイル画像*/
.image-gallery-thumbnail img {
    width: 100%;
    height: 60px;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    background-color: black;
}
