/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}


/*フォーカス時にChromeなどが自動付加する枠線を消す*/
button:focus {
    outline: none !important;
}


/** label 縦並び時のマージンを無くす*/
label {
    margin-bottom: 0 !important;
}


/* スクロール無効化 */
.body-scrolllock {
    overflow: hidden;
    padding-right: 17px;
}
