/* #region viewer */

/** ビューワ */
#viewer {
    margin: auto;
    width: 100%;
    height: 100%;
    min-width: 200px;
}

/* Mui の z-indx を考慮して上書き
 * https://material-ui.com/customization/z-index/
*/

/** モーダル */
#modal-root {
    z-index: 1350;
}

/** ズームメニュー */
.gc-dd-menu {
    z-index: 1350;
}

/** カスタムエクスポートボタン アイコン*/
.custom-print {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' focusable='false' aria-hidden='true' viewBox='0 0 24 24'><path fill='%23205F78' d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'></path></svg>");
    background-position: center;
    background-repeat: no-repeat;
}

.custom-print-disabled {
    opacity: .38 !important;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' focusable='false' aria-hidden='true' viewBox='0 0 24 24'><path fill='%23205F78' d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'></path></svg>");
    background-position: center;
    background-repeat: no-repeat;
}


/* #endregion viewer */


/* #region list */

/** レポートリスト */
.main {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.main-nav.navbar {
    padding: 0;
    background: #F1F1F1;
    color: #333;
    width: 200pt;
}

#list-heading {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

    #list-heading::after {
        content: "";
        display: block;
        left: 50%;
        width: 80%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.11);
        transform: translateX(-50%);
        color: #0c5f78;
        position: absolute;
        top: 50px;
    }

#reportsList {
    padding: .5rem 0;
    height: calc(100vh - 50px - 2em);
    width: 100%;
    overflow: auto;
    flex-wrap: nowrap;
}

.reportList_item {
    cursor: pointer;
    color: #2b2b2b;
    padding: 0 1rem;
    font-size: 15px;
    height: 34px;
    display: flex;
    align-items: center;
    transition-duration: .2s;
    flex-shrink: 0;
}

    .reportList_item > span {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .reportList_item:hover {
        color: white;
        background-color: #1b5166;
    }

    .reportList_item.active:hover {
        color: white;
        background-color: #1b5166;
    }

    .reportList_item.active {
        color: white;
        background-color: #3b7186;
    }

/* #endregion list */
